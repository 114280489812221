import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import Header from './header';
import Footer from './footer';
import s from './layout.less';
// import AppQcode from '../../components/AppQcode';


export default function Layout(props) {
    useStyles(s);
    const { route } = props;

    // const [showAppQcode, setShowAppQcode] = useState(false);
    
    // const [showTop, setShowTop] = useState(false);

    // const handleScroll = () => {// 滚动呈现
    //     setShowTop(window.pageYOffset >= 800);
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);
    const [appTop, setAppTop] = useState(false);
    const [xcxTop, setXcxTop] = useState(false);
    const [appBottom, setAppBottom] = useState(false);
    const [xcxBottom, setXcxBottom] = useState(false);
    const checkRouterChunks = (checNameArr = []) => {
        if (checNameArr.includes(route.chunks[0])) {
            return true;
        }
        return false;
    };
    const goPage = (link) => {
        window.open('_self').location = link;
    }
    return (
        <>
            {
                route.chunks.indexOf('home') !== -1 ?
                    <div className="site-layout home">
                        <Header needFixed={route.chunks.indexOf('home') !== -1} />
                        <div className="page-main">
                            {route.component}
                            {/* {showTop ?
                                <div>
                                    <span className="go-top" onClick={() => document.body.scrollIntoView({ behavior: 'smooth' })}>
                                        <i className="iconfont icon-top">&#xe77c;</i>
                                    </span>
                                </div>
                                : null} */}
                            <div className="go-top">
                                <div onMouseLeave={() => { setAppTop(false) }} onMouseEnter={() => { setAppTop(true) }}>
                                    <img className='icon-top' src={require("../../images/common/phone.png")} />
                                    APP下载
                                </div>
                                <div onMouseLeave={() => { setXcxTop(false) }} onMouseEnter={() => { setXcxTop(true) }}>
                                    <img className='icon-top' src={require("../../images/common/xcx.png")} />
                                    小程序
                                </div>
                                <div onClick={() => document.body.scrollIntoView({ behavior: 'smooth' })} style={{ border: 'none' }}>
                                    <img className='icon-top' src={require("../../images/common/back.png")} />
                                    回到顶部
                                </div>
                            </div>
                            {
                                appTop ? <>
                                    <div className='appQcode left' >
                                        <img src={require("../../images/common/app-down.png")} />
                                        <div>扫码或在应用市场</div>
                                        <div>搜索<span>"东楚优房"</span>下载</div>
                                    </div></> : null
                            }
                            {
                                xcxTop ? <>
                                    <div className='appQcode bottomleft' >
                                        <img src={require("../../images/common/xcx-home.png")} />
                                        <div>微信扫一扫</div>
                                        <div>打开<span>"东楚优房"</span>小程序</div>
                                    </div></> : null
                            }
                        </div>
                        <Footer />
                    </div>
                    : <div className="site-layout">
                        <Header needFixed={route.chunks.indexOf('home') !== -1} />
                        <div className="page-main">
                            {route.component}
                            {/* {showTop ?
                                <span className="go-top" onClick={() => document.body.scrollIntoView({ behavior: 'smooth' })}>
                                    <i className="iconfont icon-top">&#xe77c;</i>
                                </span> : null} */}
                            
                                {
                                    checkRouterChunks(['decorationProcurement', 'goodsDetail']) ? (
                                        <div className="go-top">
                                            <div onClick={()=>{goPage('/onlineShop')}}>
                                                    <img className='icon-top' src={require("../../images/common/home-zxsc.png")} />
                                                    在线商城
                                                </div>
                                                <div onMouseLeave={() => { setAppBottom(false) }} onMouseEnter={() => { setAppBottom(true) }}>
                                                    <img className='icon-top' src={require("../../images/common/home-zxsq.png")} />
                                                    在线申请
                                                </div>
                                            <div onClick={() => document.body.scrollIntoView({ behavior: 'smooth' })} style={{ border: 'none' }}>
                                                <img className='icon-top' src={require("../../images/common/back.png")} />
                                                回到顶部
                                            </div>
                                        </div>
                                ) :
                                    <div className="go-top">
                                        <div onMouseLeave={() => { setAppBottom(false) }} onMouseEnter={() => { setAppBottom(true) }}>
                                            <img className='icon-top' src={require("../../images/common/phone.png")} />
                                            APP下载
                                        </div>
                                        <div onMouseLeave={() => { setXcxBottom(false) }} onMouseEnter={() => { setXcxBottom(true) }}>
                                            <img className='icon-top' src={require("../../images/common/xcx.png")} />
                                            小程序
                                        </div>
                                        <div onClick={() => document.body.scrollIntoView({ behavior: 'smooth' })} style={{ border: 'none' }}>
                                            <img className='icon-top' src={require("../../images/common/back.png")} />
                                            回到顶部
                                        </div>
                                    </div>
                                }
                            {
                                appBottom ? <>
                                    <div className={`appQcode ${checkRouterChunks(['decorationProcurement']) ? 'decoration-left' : 'left'}` } >
                                        <img src={require("../../images/common/app-down.png")} />
                                        <div>扫码或在应用市场</div>
                                        <div>搜索<span>"东楚优房"</span>下载</div>
                                    </div></> : null
                            }
                            {
                                xcxBottom ? <>
                                    <div className='appQcode bottomleft' >
                                        <img src={require("../../images/common/xcx-home.png")} />
                                        <div>微信扫一扫</div>
                                        <div>打开<span>"东楚优房"</span>小程序{ checkRouterChunks(['decorationProcurement']) ? 1 : 2}</div>
                                    </div></> : null
                            }
                        </div>
                        <Footer />
                    </div>

            }
        </>
    );
}