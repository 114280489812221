import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.less'

export default function Footer () {
    useStyles(s);

    const url = typeof window !== 'undefined' ? window.location.pathname.replace(/[/]/g, '') : '';
    const isHome = url === '';
    // const isDetail = url.indexOf('detail') >= 0 ? true : false;
    // const isList = url.indexOf('list') >= 0 ? true : false;

    const nowDate = (new Date().toISOString()).slice(0, 10);
    const storageDate = typeof window !== 'undefined' ? localStorage.getItem('good_house_time') : '';
    const [showGoodHouse, setShowGoodHouse] = useState(false);

    useEffect(() => {
        let flag = false;
        if (!storageDate) {
            flag = true;
        } else if (nowDate !== storageDate) {
            localStorage.removeItem('good_house_time');
            flag = true;
        }
        setShowGoodHouse(flag);
    }, typeof window !== 'undefined' ? [window.location.href] : [])

    const closeGoodHouse = () => {
        localStorage.setItem('good_house_time', nowDate);
        setShowGoodHouse(false);
    };

    return (
        <>
            <div className="page-footer">
                <div className="page-footer-info" style={{ marginBottom: isHome && showGoodHouse ? '134px' : '0px' }}>
                    免责声明:本站信息均经过管理员审核，本站对信息的真实性负责。如有纠纷，请联系下方邮箱反馈，本站会介入处理，涉及违法犯罪行为移交第三方公安机关处理。<br />
                    <a href="/entryInstructions?type=平台监管政策及处理办法" target="_blank">平台监管政策及处理办法</a>
                    <a href="/entryInstructions?type=信息内容审核制度" target="_blank">信息内容审核制度</a>
                </div>
                <div className="page-footer-info" style={{ marginBottom: isHome && showGoodHouse ? '134px' : '0px' }}>
                    Copyright © 2024  黄石市东楚优房供应链有限公司 版权所有  <i /><a href="/policy" target="_blank">隐私协议</a><br />
                    <img src={require("../../../images/common/beian.png")} />
                    网站备案号：<a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备2024035720号-1</a><br />
                    地址:湖北省黄石市黄石港区黄石大道1399号 电话:18696278900 邮箱:yfgyl2024@163.com
                </div>
            </div>
            {
                isHome && showGoodHouse ?
                    <div className="layer_good_house">
                        <div>
                            <img className='app' src={require("../../../images/common/app-down.png")} />
                            <img className='xcx' src={require("../../../images/common/xcx-home.png")} />
                            <img className="phone" src={require("../../../images/common/home-bottom-phone.png")} />
                            <img className="close" onClick={closeGoodHouse} src={require("../../../images/common/close.png")} />
                        </div>
                    </div> : null
            }

        </>
    )
}
