import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import Link from '../../../components/Link';
import Login from '../../../components/login';
import s from './index.less';

export default function Home (props) {
    useStyles(s);

    const url = typeof window !== 'undefined' ? window.location.pathname.replace(/[/]/g, '') : '';
    const [isHome, setIsHome] = useState({ show: false });
    const [navInfo, setNavInfo] = useState({
        list: ['首页', '二手房', '租房', '新房', '关于我们','集采商城','商家入驻','我要入驻'],
        navSelected: ['必选好房', '店长力荐'],
        link: ['/', '/ershoufang/list', '/zufang/list', '/xinfang/list', '/aboutUs','/onlineShop','/storeList','/toJoin'],
        bar: '-9999999px'
    });
    const navClickFun = (index) => {
        setNavInfo({
            ...navInfo,
            selected: index,
            bar: index > 7 ? '0px' : `${96 * index}px`
        });
    };

    const handleScrolls = () => {// 滚动呈现
        setIsHome({
            ...isHome,
            show: !navInfo.selected ? (window.pageYOffset >= 300) : false
        });
    };

    useEffect(() => {
        if (navInfo.selected == 0) {
            window.addEventListener('scroll', handleScrolls);
        } else {
            window.removeEventListener('scroll', handleScrolls);
        }
    }, [navInfo, setNavInfo]);

    useEffect(() => {

        let index = -1;
        if (url === '') {
            index = 0;
        } else if (url === 'policy' || url === 'protocol') {
            index = 0;
        } else if (url === 'focushouse') {
            index = 5;
        } else {
            index = [null, 'ershoufang', 'zufang', 'xinfang', 'aboutUs','onlineShop','storeList','toJoin'].findIndex(item => url.indexOf(item) >= 0);
        }
        if (index >= 0) {
            setNavInfo({
                ...navInfo,
                selected: index,
                bar: index > 0 && index < 8 ? `${96 * index}px` : '0px'
            });
        }
    }, typeof window !== 'undefined' ? [window.location.href, props.needFixed] : [props.needFixed]);

    return (
        <>
            {
                props.needFixed ?
                    <>
                        <div className={isHome.show ? "page-header fixed" : "page-header"}>
                            <div className="nav-box">
                                <div className="nav" style={{ paddingTop: isHome.show ? '14px' : '12px' }}>
                                    <Link to="/">
                                        <img alt="" style={{ width: '224px',paddingTop: isHome.show ? '4px' : '0px' }} src={require(`../../../images/common/${isHome.show ? "page-logo.png" : "logo.png"}`)} />
                                    </Link>
                                    <span className={isHome.show ? "location public" : "location"}>
                                        <i className="iconfont icon-ex city">&#xe780;</i>
                                        黄石
                                    </span>
                                </div>
                                <div style={{ width: '332px', float: 'right' }}>
                                    <Login isHome={!isHome.show} navInfo={navInfo.selected === 0 ? -1 : navInfo.selected} scrolls={handleScrolls} />
                                </div>
                                <div className={isHome.show ? "nav public" : "nav"}>
                                    <div className="nav-bar" style={{ left: navInfo.selected == 8 ? '-9999999px' : navInfo.bar }} />
                                    <ul className="page-nav">
                                        {navInfo.list.map((items, index) => {
                                            return (<li key={index} className={(navInfo.selected === index || (navInfo.selected > 4 && index === 0)) ? 'active' : ''}>
                                                <div className={index === 1 ? 'ershoufangOn' : ''}>
                                                    <Link to={navInfo.link[index]}>{items}</Link>
                                                </div>
                                            </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div >
                        <div className="header-hr-content" />
                    </>
                    : <>
                        <div className="page-header">
                            <div className="nav-box">
                                <div className="nav" style={{ paddingTop: '12px' }}>
                                    <Link to="/" onClick={() => navClickFun(0)}>
                                        <img alt="" style={{ width: '224px',paddingTop: '4px' }} src={require("../../../images/common/page-logo.png")} />
                                    </Link>
                                    <span className="location public"><i className="iconfont icon-ex">&#xe780;</i>黄石</span> :
                                </div>
                                <div style={{ width: '332px', float: 'right' }}>
                                    <Login navInfo={navInfo.selected} scrolls={handleScrolls} />
                                </div>
                                <div className="nav public">
                                    <div className="nav-bar" style={{ left: navInfo.selected == 8 ? '-9999999px' : navInfo.bar }} />
                                    <ul className="page-nav"> 
                                        {navInfo.list.map((items, index) => {
                                            return (<li key={index} className={(navInfo.selected === index) ? 'active' : ''}>
                                                <Link to={navInfo.link[index]} onClick={() => navClickFun(index)}>{items}</Link>
                                            </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div >
                        <div className="header-hr-content" />
                    </>
            }
        </>
    )
}