import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState, } from 'react';
import s from './index.less';
import './main';
import { SLIDERCAPTCHA } from '../../service/api';
import request from '../../service/request';

export default function Slider (props) {
    useStyles(s);
    const [captcha, setCaptcha] = useState(null)
    useEffect(() =>{
         setCaptcha(window.sliderCaptcha({
            id: 'captcha',
			width: 280,
			height: 180,
			sliderL: 42,
			sliderR: 9,
			offset: 5,
			loadingText: '正在加载中...',
			failedText: '再试一次',
			barText: '向右滑动填充拼图',
			repeatIcon: 'fa fa-redo',
			setSrc () {},
			onRefresh () {},
            onSuccess() {
                props.setSliderCaptcha(true);
                props.sendSMS();
            },
            onFail() {
                props.setSliderCaptcha(false);
            },
            remoteUrl: SLIDERCAPTCHA,
            async verify(arr, url) {
                let ret = false;
                const result = await request.POST({
                    url,
                    params: arr
                });
                if(result.code === 0) {
                    ret = result.data
                }
                return ret;
            }
        }));
    }, []);

    const sliderCaptchaClose = function() {
        props.setIsSlider(false);
        captcha.reset();
    }   
    return (     
        <>
            <div className="slidercaptcha card">
                <div className="card-header">
                    <span>请完成安全验证!</span>
                    <div className="slidercaptcha-close" onClick={()=> sliderCaptchaClose()}>×</div>
                </div>
                <div className="card-body">
                    <div id="captcha" />
                </div>
            </div>
        </> 
    )
}